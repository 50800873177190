<template>
  <div style="width: 100%; height: 100%">
    <GmapMap
      id="map"
      ref="gmap"
      :zoom="zoom"
      :center="centerGmap"
      :options="gmapSettings"
    >
      <gmap-circle
        v-for="(m, index) in items"
        :key="index"
        @click="centerGmap = getPosePosition(m)"
        :center="getPosePosition(m)"
        :options="{
          strokeColor: m.active
            ? '#00FF00'
            : m.Action == '02'
            ? '#0012FF'
            : '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: m.active
            ? '#00FF00'
            : m.Action == '02'
            ? '#0012FF'
            : '#FF0000',
          fillOpacity: 0.35,
        }"
        :radius="0.2"
        :clickable="true"
        :draggable="false"
      >
      </gmap-circle>
    </GmapMap>

    <v-navigation-drawer
      v-model="rightDrawer"
      class="right-drawer"
      dark
      hide-overlay
      stateless
      absolute
      right
    >
      <v-checkbox
        class="ml-6"
        :value="selectAll"
        @change="selectAllItems"
      ></v-checkbox>

      <v-btn @click="showInfos = !showInfos" block color="#131e30" dark>
        Informations
      </v-btn>

      <p v-if="showInfos" style="color: white; font-size: 11px">
        <span style="margin-left: 15px"
          >Total : {{ items.length }} données</span
        >

        <br />

        <span style="margin-left: 15px"
          >Adresse : {{ debugData.reportInfos.address }}</span
        >

        <br />

        <span style="margin-left: 15px"
          >Dates : {{ debugData.reportInfos.dates }}</span
        >

        <br />

        <span style="margin-left: 15px"
          >Distance : {{ debugData.reportInfos.distanceThreshold }}</span
        >

        <br />

        <span style="margin-left: 15px"
          >Formule : {{ debugData.reportInfos.formula }}</span
        >

        <br />

        <span style="margin-left: 15px"
          >Nombre de pieux : {{ debugData.reportInfos.numberOfPiles }}</span
        >

        <br />

        <span style="margin-left: 15px"
          >Latitude : {{ debugData.reportInfos.lat }}</span
        >

        <br />

        <span style="margin-left: 15px"
          >Longitude : {{ debugData.reportInfos.lng }}</span
        >
      </p>

      <v-data-table
        dark
        v-if="items && items.length > 0"
        :headers="headers"
        fixed-header
        :items="items"
        width="800px"
        :items-per-page="8"
        :footer-props="{ 'items-per-page-options': [8, 10, 25, 50, 100, 200] }"
        multi-sort
        dense
        class="elevation-1"
      >
        <template v-slot:item="{ item, index }">
          <tr @click="selectItem(item)">
            <td>
              <v-checkbox v-model="item.active"></v-checkbox>
            </td>

            <td>{{ index }}</td>

            <td v-if="item.Action === '02'" style="text-align: center">
              <v-img height="20" src="viss.png" contain />

              {{ "02" }}
            </td>

            <td v-else style="text-align: center">
              <v-img height="20" contain src="deviss.png" />
            </td>

            <td style="text-align: center">
              {{ item.Laser }}

              <br />

              <v-progress-linear
                style="width: 50px"
                :color="getColorPercent(item.hauteur_percent)"
                :value="item.hauteur_percent"
              >
              </v-progress-linear>
            </td>

            <td style="text-align: center">
              {{ parseInt(item.Torque.toString()) }}

              <v-progress-linear
                style="width: 50px"
                :color="getColorPercent(item.torque_percent)"
                :value="getTorqPercent(item.torque_int)"
              >
              </v-progress-linear>
            </td>

            <td>{{ item.GPS_Y.toFixed(6) }}</td>

            <td>{{ item.GPS_X.toFixed(6) }}</td>

            <td>{{ item.GPS_Z.toFixed(2) }}</td>

            <td>
              {{
                item.Horodatage.split("T")[0] +
                "__" +
                item.Horodatage.split("T")[1].slice(0, 8)
              }}
            </td>

            <td
              :class="{ 'error-back': item.Angle_X > 15 || item.Angle_X < -15 }"
            >
              {{ item.Angle_X }}
            </td>

            <td
              :class="{ 'error-back': item.Angle_Y > 15 || item.Angle_Y < -15 }"
            >
              {{ item.Angle_Y }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-navigation-drawer>

    <RoundButton
      :icon="intervalId ? 'mdi-stop' : 'mdi-play'"
      right="85px"
      @onClick="intervalId ? stopTimeline() : selectTimeline()"
    />

    <RoundButton
      icon="mdi-filter-variant"
      right="15px"
      @onClick="rightDrawer = !rightDrawer"
    />
    <v-btn
      fab
      absolute
      left
      style="top: 0px"
      color="#131E30"
      class="mt-5"
      dark
      @click="$router.back()"
    >
      <v-icon>mdi-arrow-u-left-top</v-icon>
    </v-btn>

    <!-- max-height:65px; -->
    <div v-if="currentItem" elevation="3" class="bottom-panel">
      <span class="inline-span">
        <v-checkbox dark v-model="currentItem.active"></v-checkbox>
      </span>
      <span class="inline-span">{{ currentItem.index }}</span>
      <span
        class="inline-span"
        v-if="currentItem.Action === '02'"
        style="text-align: center"
      >
        <v-img height="20" src="viss.png" contain />
      </span>
      <span class="inline-span" v-else style="text-align: center">
        <v-img height="20" contain src="deviss.png" />
      </span>
      <span class="inline-span" style="text-align: center">
        {{ currentItem.Laser }}
        <br />
        <v-progress-linear
          style="width: 50px"
          :color="getColorPercent(currentItem.hauteur_percent)"
          :value="currentItem.hauteur_percent"
        >
        </v-progress-linear>
      </span>

      <span class="inline-span" style="text-align: center">
        {{ parseInt(currentItem.Torque.toString()) }}
        <v-progress-linear
          style="width: 50px"
          :color="getColorPercent(currentItem.torque_percent)"
          :value="getTorqPercent(currentItem.torque_int)"
        >
        </v-progress-linear>
      </span>
      <span class="inline-span">{{
        currentItem.Horodatage.split("T")[1].slice(0, 8)
      }}</span>
      <span
        class="inline-span"
        :class="{
          'error-back': currentItem.Angle_X > 15 || currentItem.Angle_X < -15,
        }"
      >
        {{ currentItem.Angle_X }}
      </span>
      <span
        class="inline-span"
        :class="{
          'error-back': currentItem.Angle_Y > 15 || currentItem.Angle_Y < -15,
        }"
      >
        {{ currentItem.Angle_Y }}
      </span>
      <apexchart
        ref="chart"
        type="line"
        height="150"
        :options="chartOptions"
        :series="series"
        @zoomed="test"
      >
      </apexchart>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import RoundButton from "@/components/buttons/RoundButton";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GoogleDebug",
  components: {
    apexchart: VueApexCharts,
    RoundButton,
  },
  data: () => ({
    gmap: undefined,
    gmapSettings: {
      mapTypeId: "satellite",
      disableDefaultUI: true,
      minZoom: 6,
      maxZoom: 30,
    },
    centerGmap: { lat: 46.05030879999986, lng: -71.38202359999987 },
    rightDrawer: false,
    items: [],
    gmapItems: [],
    zoom: 9,
    selectAll: false,
    first: null,
    last: null,
    headers: [
      {
        align: "start",
        sortable: false,
        value: "active",
      },
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "ID_Pose",
      },
      {
        text: "Drilling",
        value: "isDrilling",
        align: "start",
      },
      { text: "Laser", value: "Laser" },
      { text: "Torque (calc)", value: "Torque" },
      { text: "Latitude", value: "GPS_Y" },
      { text: "Longitude", value: "GPS_X" },
      { text: "Altitude", value: "GPS_Z" },
      { text: "Date", value: "Horodatage" },
      { text: "Angle X", value: "Angle_X" },
      { text: "Angle Y", value: "Angle_Y" },
      /*
            ID_Pose: number
            Machine: number
            Capteur: number
            Horodatage: Date
            Action: number
            Torque: number
            Pression: number
            Laser: number
            GPS_X: Prisma.Decimal
            GPS_Y: Prisma.Decimal
            GPS_Z: Prisma.Decimal
            Angle_X: number
            Angle_Y: number*/
    ],
    showInfos: false,
    maxTorq: 999999,
    maxHauteur: 999999,
    intervalId: null,
    currentItem: null,
    series: [
      {
        name: "Hauteur",
        data: [],
      },
    ],
    chartOptions: {
      theme: {
        mode: "dark",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "dark",
          shadeIntensity: 0.65,
        },
      },
      chart: {
        height: 150,
        type: "line",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        /*events: {
                            zoomed: function (chartContext, { xaxis, yaxis }) {
                                }
                        } */
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      annotations: {
        xaxis: [],
      },
    },
    phaseIndexes: [],
  }),
  mounted() {
    const scope = this;
    this.$nextTick(() => {
      this.$refs.gmap.$mapPromise.then((map) => {
        scope.gmap = map;
        //console.log("MAP ", scope.gmap)
      });
    });

    setTimeout(() => {
      this.refreshData();
    }, 1000);
  },
  computed: {
    ...mapState({
      debugData: "debugData",
    }),
  },
  methods: {
    getPosePosition(item) {
      return {
        lat: item.GPS_Y,
        lng: item.GPS_X,
      };
    },
    selectItem(item) {
      const scope = this;
      this.currentItem = item;
      const xAnnotations = this.getXAnnotations(item);
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          annotations: {
            yaxis: [
              {
                y: this.maxHauteur,
                borderColor: "#FF0000",
                yAxisIndex: 0,
                label: {
                  show: false,
                },
              },
            ],
            xaxis: xAnnotations,
          },
        },
      };

      //console.log('Select Item', index)
      //const foundIdx = this.items.findIndex((i) => i.index == index)

      item.active = !item.active;
    },
    clickShift(index, e) {
      if (this.last == null && this.first != null) {
        this.last = index;

        const active = !this.items[this.first].active;

        for (let i = this.first; i < this.last; i++) {
          this.items[i].active = !active;
        }

        //console.log("FIRST", this.first, "LAST", this.last)

        this.$forceUpdate();
        this.first = null;
        this.last = null;
      } else if (this.first == null) {
        this.first = index;
        this.last = null;
      }
    },
    selectAllItems() {
      const scope = this;
      this.selectAll = !this.selectAll;
      this.items.forEach((item) => {
        item.active = scope.selectAll;
      });
    },
    refreshData() {
      this.showPiles();
    },
    showPiles() {
      const scope = this;

      let lastRow = null;

      //console.log("DEBUG DATA ", this.debugData.data.data)

      const data = this.debugData.data.data;

      this.items = JSON.parse(JSON.stringify(data));

      const ids = this.items.map((object) => {
        object.active = false;
        object.torque_int = parseInt(object.Torque.toString());
        return object.torque_int;
      });

      const ids2 = this.items.map((object) => {
        object.hauteur_int = parseInt(object.Laser.toString());
        return object.hauteur_int;
      });

      const max = Math.max(...ids);
      const maxHauteur = Math.max(...ids2);
      this.maxTorq = max;
      this.maxHauteur = maxHauteur;

      const peakIndexes = [];

      for (let i = 0; i < this.items.length; i++) {
        this.items[i].deltaZ = maxHauteur - this.items[i].hauteur_int;

        if (lastRow) {
          const diff =
            new Date(this.items[i].Horodatage) - new Date(lastRow.Horodatage);
          if (diff >= 10000 && this.items[i].deltaZ < 60) {
            peakIndexes.push(i);
          }
        }

        this.items[i].hauteur_percent = parseInt(
          ((this.items[i].Laser * 100) / this.maxHauteur).toString()
        );
        this.items[i].torque_percent = parseInt(
          ((this.items[i].torque_int * 100) / this.maxTorq).toString()
        );
        lastRow = this.items[i];
      }

      //console.log('PEAK INDEXES', peakIndexes)

      const phaseIndexes = [];
      let lastPeak = null;
      for (let i = 0; i < peakIndexes.length; i++) {
        if (i == 0) {
          phaseIndexes.push(peakIndexes[0]);
        } else {
          if (peakIndexes[i] - lastPeak < 10) {
            phaseIndexes[phaseIndexes.length - 1] = peakIndexes[i];
          } else {
            phaseIndexes.push(peakIndexes[i]);
          }
        }
        lastPeak = peakIndexes[i];
      }
      this.phaseIndexes = phaseIndexes;
      //console.log('PHASES INDEXES', phaseIndexes)

      //
      /**/

      let text = "";
      const arr = this.items.map((m) => {
        text += m.Laser + ", ";
        return [m.Horodatage, m.Laser];
      });

      //console.log(this.getIncreaseDecrease(arr))

      //console.log(text)
      this.series[0].data = arr;

      this.selectItem(this.items[0]);
      //console.log('MIN ARRAY', this.minArray(arr));

      //console.log('MIN AND MAXES', this.findLocalMaximaMinima(arr.length, arr))

      this.centerGmap = {
        lat: this.items[0].GPS_Y,
        lng: this.items[0].GPS_X,
      };

      this.zoom = 22;
    },
    getHauteurPercent(Laser) {
      return parseInt(((Laser * 100) / 400).toString());
    },
    getColorPercent(torque_percent) {
      if (torque_percent < 20) {
        return "#B2FF59";
      } else if (torque_percent < 40) {
        return "#FFEA00";
      } else if (torque_percent < 60) {
        return "#FFAB40";
      } else if (torque_percent < 80) {
        return "#FF1744";
      } else {
        return "#FF1744";
      }
    },
    getTorqPercent(torq) {
      const percent = parseInt(((torq * 100) / this.maxTorq).toString());
      // console.log('Torque percent : ', percent)
      return percent;
    },
    selectTimeline() {
      var currentIndex = 0;
      const scope = this;
      scope.intervalId = setInterval(function () {
        //array[currentIndex].css('color', 'black');
        scope.selectItem(scope.items[currentIndex]);
        currentIndex++;
        // We've reached the end of the array, stop calling this function
        if (currentIndex == scope.items.length) clearInterval(scope.intervalId);
      }, 150);
    },
    stopTimeline() {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.items.forEach((item) => {
        item.active = false;
      });

      this.$forceUpdate();
    },
    test(chartContext, { xaxis, yaxis }) {
      //console.log('ZOOMED', chartContext, xaxis, yaxis)
      //console.log('MIN', chartContext.axes.w.config.xaxis.min)
      //console.log('MAX', chartContext.axes.w.config.xaxis.max)
      if (chartContext.axes.w.config.xaxis.max) {
        const max = new Date(chartContext.axes.w.config.xaxis.max).getTime();

        const min = new Date(chartContext.axes.w.config.xaxis.min).getTime();

        //console.log(max, min)
        const arrayToSelect = this.items.filter((i) => {
          let date = new Date(i.Horodatage).getTime();
          return date < max && date > min;
        });
        //console.log('Array to select', arrayToSelect)
        for (let i = 0; i < arrayToSelect.length; i++) {
          const idx = this.items.findIndex(
            (it) => it.index === arrayToSelect[i].index
          );

          if (idx > -1) {
            this.selectItem(this.items[idx]);
          }
        }

        this.$forceUpdate();
      } else {
        this.stopTimeline();
      }
    },
    getIncreaseDecrease(arr) {
      const increaseDecreaseArray = [];
      for (let i = 0; i < arr.length; i++) {
        if (i != arr.length - 1) {
          if (arr[i][1] >= arr[i + 1][1]) {
            increaseDecreaseArray.push("-");
          } else {
            increaseDecreaseArray.push("+");
          }
        } else {
          if (arr[arr.length - 2][1] >= arr[arr.length - 1][1]) {
            increaseDecreaseArray.push("-");
          } else {
            increaseDecreaseArray.push("+");
          }
        }

        // hauteur
      }

      return increaseDecreaseArray;
    },
    getXAnnotations(item) {
      //console.log('get x annotations')
      const annotations = [
        {
          x: new Date(item.Horodatage).getTime(),
          borderColor: "#FFF",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "Cible",
            style: {
              color: "#fff",
              background: "#0012FF",
            },
          },
        },
      ];
      // one length - 1
      for (let i = 0; i < this.phaseIndexes.length - 1; i++) {
        //console.log(this.phaseIndexes[i])
        annotations.push({
          x: new Date(this.items[this.phaseIndexes[i]].Horodatage).getTime(),
          x2: new Date(
            this.items[this.phaseIndexes[i + 1]].Horodatage
          ).getTime(),
          fillColor: Math.floor(Math.random() * 16777215).toString(16),
          borderColor: "#FFF",
          opacity: 0.2,
          yAxisIndex: 0,
          label: {
            show: false,
          },
        });

        if (i == this.phaseIndexes.length - 2) {
          annotations.push({
            x: new Date(
              this.items[this.phaseIndexes[i + 1]].Horodatage
            ).getTime(),
            x2: new Date(
              this.items[this.items.length - 1].Horodatage
            ).getTime(),
            fillColor: Math.floor(Math.random() * 16777215).toString(16),
            borderColor: "#FFF",
            opacity: 0.2,
            yAxisIndex: 0,
            label: {
              show: false,
            },
          });
        }

        // console.log(annotations)
      }

      return annotations;
    },
  },
};
</script>
<style scoped>
#map {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

::v-deep .v-navigation-drawer__content {
  overflow: hidden;
}

::v-deep .v-data-table__wrapper {
  overflow-y: scroll;
  height: 580px;
}

::v-deep .theme--dark.v-navigation-drawer {
  background: #131e30;
}

::v-deep .theme--dark.v-data-table {
  background: #1a2639;
}

::v-deep .theme--dark.v-data-table.v-data-table--fixed-header thead th {
  background: #131e30;
}

.inline-span {
  display: inline-block;
  width: 60px;
  vertical-align: middle;
  text-align: center;
}

::v-deep .apexcharts-svg {
  background: #131e30 !important;
}

.right-drawer {
  width: 500px;
  user-select: text;
}

.bottom-panel {
  position: fixed;
  padding-left: 15px;
  padding-bottom: 10px;
  border-radius: 8px;
  color: white;
  left: calc(50% - 250px);
  bottom: 10px;
  background-color: #131e30;
  width: 500px;
}
</style>
